@use '@angular/material' as mat;
@include mat.core();

// Import Conflow core library
@import "./app/shared/scss/core";


// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import "./app/features/channel/channel.theme.scss";
// Define a mixin for easier access
@mixin components-theme($theme) {
    @include channel-theme($theme);
}

$my-app-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      use-system-variables: true,
    ),
    typography: (
      use-system-variables: true,
    )
  )
);

html {
  @include mat.all-component-themes($my-app-theme);
  @include mat.system-level-colors($my-app-theme);
  @include mat.system-level-typography($my-app-theme);
}

:root {
  --sys-body-large-font: "Open Sans", "Noto Sans JP";
  --mdc-text-button-label-text-color:#000000;
  --mat-table-row-item-outline-color: #0000001f;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-background-color: #ffffff;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, .12);
  --mat-sidenav-border-radius: 0px;
  --mat-sidenav-content-background-color: #fafafa;
  --mat-table-background-color: #ffffff;
  --mat-tab-header-label-text-tracking: normal;
  --mat-table-row-item-label-text-tracking: normal;
  --mdc-filled-button-label-text-tracking: normal;
  --mat-table-header-headline-line-height: normal;
  --mat-table-row-item-label-text-line-height: normal;
  --mdc-filled-button-container-height: 36px;
  --mdc-filled-button-container-shape: 4px;
  --mdc-list-list-item-label-text-font: 'Open Sans', 'Noto Sans JP';
  --mdc-list-list-item-label-text-line-height: normal;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-text-button-label-text-tracking: normal;
  --mat-table-header-headline-tracking: normal;
  --mat-select-trigger-text-line-height: normal;
  --mat-select-trigger-text-size: 14px;
  --mat-paginator-disabled-icon-color:rgba(0, 0, 0, .12);
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-container-height: 36px;
  --mdc-dialog-container-shape: 4px;
  --mdc-dialog-container-color: #ffffff;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: .0125em;
  --mat-dialog-container-min-width: 0px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-line-height: normal;
  --mat-form-field-container-text-tracking: normal;
  --mdc-dialog-container-elevation-shadow : 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-outlined-button-label-text-font: "Open Sans", "Noto Sans JP";
  --mdc-outlined-button-label-text-size: 14px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-font: "Open Sans", "Noto Sans JP";
  --mat-filled-button-horizontal-padding: 16px;
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mat-paginator-container-text-tracking: .0333333333em;
  --mdc-dialog-supporting-text-line-height: normal;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-tracking: normal;
  --mat-menu-container-color: white;
  --mat-app-elevation-shadow-level-2: 0 5px 5px -3px #0003,0 8px 10px 1px #00000024,0 3px 14px 2px #0000001f;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-line-height: normal;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-spacing : 16px;
  --mat-menu-item-label-text-weight: 400;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-toolbar-container-background-color: whitesmoke;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mat-switch-label-text-line-height: normal;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-line-height: normal;
  --mdc-switch-track-width: 36px;
  --mat-switch-label-text-tracking: normal;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-size: 18px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0px;
  --mat-switch-with-icon-handle-size: 20px;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-size : 18px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 16px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 14px;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12);
  --mdc-filled-text-field-disabled-active-indicator-color:rgba(0, 0, 0, .06);
  --mdc-chip-outline-width: 0px;
  --mdc-text-button-container-shape: 4px;
  --mat-select-panel-background-color: #ffffff;
  --mdc-outlined-button-label-text-color: #000000;
}

.mat-drawer {
 border-radius: 0px !important;
}

.mat-mdc-menu-trigger {
  color: #0000008a !important;
}

.mat-mdc-outlined-button{
  border-radius: 4px !important;
}

.sidebar-toggle {
  color: #0000008a !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: 48px;
  height: 48px;
  padding: 12px;
}

.channel-members-filter {
  background-color: #039be5;
}

.channel-list-item-label {
  color: black;
}

app-channel-list-item > a.active .channel-list-item-label {
  color: #fff;
}

.actionflowedit {
  .unknownuser {
  border-radius: 18px;
  }
}

.mdc-form-field .mat-internal-form-field {
  background-color: #039be5 !important;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
  padding-top: 24px;
  padding-bottom: 8px;
}

.mat-mdc-dialog-title {
  background-color: #039be5;
}

.mdc-switch .mdc-switch__track {
 height: 14px;
}

.mdc-switch__track::before, .mdc-switch__track::after {
  border : none !important;
}

.mdc-switch--selected .mdc-switch__icon {
  fill: #fff !important;
}

.mdc-switch--selected .mdc-switch__icon--on, .mdc-switch--unselected .mdc-switch__icon--off {
  transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}